import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Joselo from "../images/joselo-mercado.png";
import MainImage from "../images/conferencias/resolviendo-nuestros-conflictos.jpeg";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

// markup
const LuminaresPage = () => {
  function resize(event) {
    const frames = document.getElementsByTagName("iframe");
    console.log(["triggered"]);
    Array.from(frames).forEach((el) => {
      const ratio = 1.777777777777778;
      const parentDivWidth = el.offsetWidth;
      const newHeight = parentDivWidth / ratio;
      el.height = newHeight;
    });
  }

  React.useEffect(() => {
    resize();
  }, []);

  if (typeof window !== 'undefined') {
    window.addEventListener("resize", resize);
  }


  return (
    <>
      <Header></Header>
      <div className="Index-page-content">
        <div className="sqs-layout sqs-grid-12 columns-12">
          <div className="row sqs-row" id="yui_3_17_2_1_1633227834863_306">
            <div
              className="col sqs-col-12 span-12"
              id="yui_3_17_2_1_1633227834863_305"
            >
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-56b231af4d088e92779c5d21"
              >
                <div className="sqs-block-content">
                  <h1 style={{ textAlign: "center" }}>Conferencias</h1>
                </div>
              </div>

              <div className="row sqs-row">
                <div className="col sqs-col-2 span-2">
                  <div className="sqs-block -block sqs-block-spacer sized vsize-1">
                    <div className="sqs-block-content">&nbsp;</div>
                  </div>
                </div>
                <div className="col sqs-col-12 span-12">
                  <div className="row sqs-row">
                    <div className="col sqs-col-6 span-6">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_22_1455045805581_72424"
                      >
                        <div className="sqs-block-content">
                          <img src={MainImage} />
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-6 span-6">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_22_1455045805581_73767"
                      >
                        <div className="sqs-block-content">
                          <p>
                            Gracia Sobre Gracia y el Ministerio Juntos & Unidos te extienden una cálida invitación a la conferencia: "Resolviendo Nuestros Conflictos", diseñada para fortalecer tu matrimonio desde una perspectiva bíblica.
                          </p>
                          <p>
                            Es el momento perfecto para cultivar un crecimiento conjunto y aprender a resolver conflictos, todo mientras nos esforzamos por ser fieles a Dios en nuestro compromiso matrimonial.
                          </p>
                          <p>
                            El Pastor Luis Méndez, miembro de la Asociación de Consejeros Bíblicos Certificados en Estados Unidos (ACBC) y con certificación de Life Coaching con la American Association of Christian Counselors. Compartirá  con nosotros su experiencia y valiosos conocimientos, para ayudarnos a crecer en pareja con base en La Palabra de Dios. No te pierdas esta oportunidad de enriquecer tu matrimonio.
                          </p>
                          <p>
                            Únete a nosotros en esta jornada única, donde compartiremos enseñanzas prácticas y bíblicas que impulsarán la salud y sanidad en tu relación.
                          </p>
                          <p>
                            ¡Te esperamos para vivir juntos este camino hacia la plenitud matrimonial bajo la gracia de Dios!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-2 span-2">
                  <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1">
                    <div className="sqs-block-content">&nbsp;</div>
                  </div>
                </div>
              </div>
              <div className="col ">

                <div className="image-button-wrapper">
                  <div
                    className="image-button sqs-dynamic-text"
                    data-width-percentage="28"
                  >
                    <div className="image-button-inner alignCenter btn-font-large color-yellow">
                      <OutboundLink target="_blank" href="https://www.eventbrite.com/e/resolviendo-nuestros-conflictos-conferencia-de-matrimonios-tickets-795399430107?aff=oddtdtcreator">REGISTRATE AQUI</OutboundLink>
                    </div>
                  </div>
                </div>
              </div>


              <div className="sqs-block spacer-block sqs-block-spacer">
                <div className="sqs-block-content sqs-intrinsic">&nbsp;</div>
              </div>

              <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1">
                <div className="sqs-block-content">&nbsp;</div>
              </div>

              {/* <div></div> */}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default LuminaresPage;
